// helpers
import { listenEvent, dispatchEvent } from 'helpers/eventMonitor';

// constants
import { TEXT_TYPING_ID } from 'constants/selectors';

let sentences;
let hasLottieSegmentCompleted;

const DEBUG = true;

switch (process.env.LANG) {

	case 'fr': {
		sentences = [
			'capter l\'attention de votre audience',
			'vos formations',
			'présenter votre produit',
			'distancer la concurrence'
		];
		break;
	}

	case 'es': {
		sentences = [
			'persuadir',
			'formaciones',
			'presentar servicios',
			'la comunicación interna',
		];
		break;
	}

	case 'de': {
		sentences = [
			'um zu überzeugen',
			'um ihre Produkte zu erklären',
			'für ihre Schulungen',
			'für ihre Interne Kommunikation',
		];
		break;
	}

	case 'uk': {
		sentences = [
			'to win over',
			'to present your services',
			'for your internal communication',
			'for your training',
		];
		break;
	}

	default:
		break;
}

// notify lottie creation animation
const onStringStart = (step) => {
	if (DEBUG) console.log(`1A. [typing] onStringStart step ${step}, will notify`);
	hasLottieSegmentCompleted = false; // reset flag
	dispatchEvent('START_CREATION_ANIM_SEGMENT', { step });
};


// pause typing anim
const onStringEnd = (step, instance) => {
	if (DEBUG) console.log(`2. onStringEnd step ${step}`);
	if (!hasLottieSegmentCompleted) {
		if (DEBUG) console.log('will pause (will be resumed once lottie anim segment completes');
		instance.freeze(); // pause typing (cursor continues blinking)
	}
	else if (DEBUG) console.log('[typing], lottie anim segment has already completed, will not freeze');
}

const typeItOptions = {
	speed: 60, // ms for each keystroke
	// startDelay: 600, (default 250), not needed if waitUntilVisible
	deleteSpeed: 20, // ms for each keystroke
	// backDelay: 4000,
	loop: true,
	// loopCount: false,
	cursor: true,
	cursorChar: '|',
	// waitUntilVisible: true,
};

$(document).ready(async () => {

	const { default: TypeIt } = await import(/* webpackChunkName: "typeit" */ 'typeit');

	// typing text animation
	const instance = new TypeIt(`#${TEXT_TYPING_ID}`, typeItOptions)
		.exec(() => { onStringStart(0); }) // will notify lottie anim
		.type(sentences[0])
		.exec(() => { onStringEnd(0, instance); }) // will freeze until lotie anim completes segment
		.pause(1000) // wait (cursor blinking)
		.delete() // erase word
		.exec(() => { onStringStart(1); }) // will notify lottie anim
		.type(sentences[1])
		.exec(() => { onStringEnd(1, instance); }) // will freeze until lotie anim completes segment
		.pause(1000) // wait (cursor blinking)
		.delete() // erase word
		.exec(() => { onStringStart(2); }) // will notify lottie anim
		.type(sentences[2])
		.exec(() => { onStringEnd(2, instance); }) // will freeze until lotie anim completes segment
		.pause(1000) // wait (cursor blinking)
		.delete() // erase word
		.exec(() => { onStringStart(3); }) // will notify lottie anim
		.type(sentences[3])
		.exec(() => { onStringEnd(3, instance); }) // will freeze until lotie anim completes segment
		.pause(1000) // wait (cursor blinking)
		.delete(); // erase word

	// get notified when lottie animation has loaded so it is ready to receive events
	listenEvent('CREATION_ANIM_LOADED', () => {
		if (DEBUG) console.log('0B. [typing] lottie anim ready, start typing');
		instance.go();
	});

	// get notified when lottie animation has finshed playing a segment
	listenEvent('CREATION_ANIM_SEGMENT_HAS_COMPLETED', (data) => {
		const { step: lottieStep } = data;
		if (DEBUG) console.log(`3B. [typing] creation anim segment has completed, step ${lottieStep}`);

		if (instance.is('frozen')) {
			if (DEBUG) console.log('[typing] un-pause typing');
			instance.unfreeze(); // continue: next step are: wait, erase word, type next sentence
		}
		// In some (rare) cases, event is received before typing completes (onStringEnd), therefore typing anim stays frozen
		else {
			if (DEBUG) console.log('[typing] still typing, will not pause typing on end');
			hasLottieSegmentCompleted = true;
		}
	});

});