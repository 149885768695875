// modules
// import firebase from 'firebase/app';
// import 'firebase/firestore';

export const connectToDatabase = async () => {

	// dynamical import => dynamic code splitting, see issue #36 - webpackChunkName comment used to specify chunk name
	const { default: firebase } = await import(/* webpackChunkName: "firebase" */ 'firebase/app');
	await import(/* webpackChunkName: "firebase/firestore" */ 'firebase/firestore');
	await import(/* webpackChunkName: "firebase/auth" */ 'firebase/auth');

	// Initialize Cloud Firestore through Firebase
	firebase.initializeApp({
		apiKey: 'AIzaSyBxLYraYNih5AmB_1pOq67ry7KwGiUulMI',
		authDomain: 'dyn-mail.firebaseapp.com',
		projectId: 'dyn-mail'
	});
	// allow undefined/null values => https://stackoverflow.com/questions/42310950/handling-undefined-values-with-firebase
	firebase.firestore().settings({
		ignoreUndefinedProperties: true,
	});

	const db = firebase.firestore(); // @TODO: use REST API (fetch) instead of Firebase SDK
	const auth = firebase.auth();

	try {
		await auth.signInAnonymously();
		return db;
	}
	catch (error) {
		console.error(error); //  error.code, error.message
		return undefined;
	}
}