export const listenEvent = (eventName, handler) => {
	const eventMonitor = document.getElementById('eventMonitor');
	if (!eventMonitor) {
		console.log(`eventMonitor not found to listen for event ${eventName}`);
		return;
	}
	const modHandler = ({ detail }) => { handler(detail); }; // get data from CustomEvent at details key
	eventMonitor.addEventListener(eventName, modHandler);
	// console.log(`eventMonitor now listening for event '${eventName}'`);

	// ... later
	// eventMonitor.removeEventListener(eventName, modHandler);
};

export const dispatchEvent = (eventName, eventData) => {
	const eventMonitor = document.getElementById('eventMonitor');
	if (!eventMonitor) {
		console.log(`eventMonitor not found to dispatch event ${eventName}`);
		return;
	}
	let event = new CustomEvent(eventName, { detail: eventData });
	eventMonitor.dispatchEvent(event);
	// console.log(`eventMonitor dispatched event '${eventName}' with data:`, eventData);
};