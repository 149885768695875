// import $ from 'jquery'; // slick-carousel is loaded as a jquery plugin. it requires jquery (included in Webflow), since it is a peer dependancy Webpack needs jquery at build time. to avoid a useless import it is defined in Webpack config with plugin ProvidePlugin

const CLIENTS_SLIDER_LIST_CLASS = 'customers-collection-list';

// wait for DOM to be ready
$(document).ready(async () => {

	await import(/* webpackChunkName: "slick-carousel" */ 'slick-carousel/slick/slick');

	// setup slick-carousel on customers "list"
	$(`.${CLIENTS_SLIDER_LIST_CLASS}`).slick({
		dots: false,
		infinite: true,
		slidesToShow: 7,
		arrows: true,
		centerMode: false,
		centerPadding: '30px',
		autoplay: true,
		autoplaySpeed: 1500,
		// Webflow breakpoints + 1
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 6,
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 4,
				}
			},
			{
				breakpoint: 479,
				settings: "unslick",
			},
		]
	});
});
