// import { gsap, ScrollTrigger, MotionPathPlugin, DrawSVGPlugin } from 'gsap/all';

// CSS selectors
const PROCESS_GRID_CLASS_SELECTOR = '.process-grid';
const PLANE_SVG_SELECTOR = '#plane';
const LINE_SVG_MASK_SELECTOR = '#processlinemask';
const LAST_4_PROCESS_GRID_BLOCKS_SELECTOR = '.process-grid__block:not(.process-grid__block--1)'; // all motion design process block execpt the first one (scénarisation)

$(document).ready(async () => {

	// specify which modules to import using Webpack magic comment webpackExports
	const { default: gsap, ScrollTrigger, MotionPathPlugin, DrawSVGPlugin } = await import(
		/* webpackExports: ["default", "gsap", "ScrollTrigger", "MotionPathPlugin", "DrawSVGPlugin"] */
		/* webpackChunkName: "gsap/all" */
		'gsap/all');

	gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, DrawSVGPlugin);

	// 0. setup timeline which starts when process grid is scrolled into view
	const tl = gsap.timeline({
		scrollTrigger: {
			trigger: PROCESS_GRID_CLASS_SELECTOR,
			start: 'top center',
			// markers: true,
			toggleActions : 'play none none none',
		},
	});
	tl.addLabel('START');

	// 1. animate plane along path
	const planeTween = gsap.to(
		// CSS selector
		PLANE_SVG_SELECTOR,
		// first argument: to
		{
			duration: 5, 
			ease: 'power1.inOut',
			motionPath:{
				path: LINE_SVG_MASK_SELECTOR,
				align: LINE_SVG_MASK_SELECTOR,
				autoRotate: true,
				alignOrigin: [0.5, 0.5]
			},
			// hide plane on complete
			onComplete: () => {
				gsap.to(PLANE_SVG_SELECTOR, { opacity: 0 })
			},
		},
	);
	tl.add(planeTween, 'START');

	// 2. draw path as plane travels it
	const processLineDrawingTimeline = gsap.timeline(); // note: svg's <mask> tag is required to have a dashed line, otherwise drawSVGPlugin draws a continue line
	// set path to 0%
	processLineDrawingTimeline.set(
		LINE_SVG_MASK_SELECTOR, {
			drawSVG: '0%' // sets the stroke to 0 (hide it)
		}
	),
	// draw SVG path's mask
	processLineDrawingTimeline.to(
		LINE_SVG_MASK_SELECTOR, {
			drawSVG: '100%', // sets the stroke to 100% (show it)
			duration: 5.05, // duration of stroke drawing
			ease: 'power1.inOut'
		}
	),
	tl.add(processLineDrawingTimeline, 'START');


	// 3. Show blocks 2 to 5 as plane travels them
	const processGridBlocksTween = gsap.fromTo(
		// CSS selector
		LAST_4_PROCESS_GRID_BLOCKS_SELECTOR,
		// first argument: from
		{ opacity: 0 },
		// second argument: to
		{
			opacity: 1,
			delay: 1,
			stagger: { each: 1.2 },
		}
	);
	tl.add(processGridBlocksTween, 'START');
});
