// helpers
import { getClientId } from 'helpers/analytics.js';
import { updateHTML } from 'helpers/html';
import { randomId } from 'helpers/random';
import { isEmpty } from 'helpers/dict';
import { mailToLinkString, emailName } from 'helpers/email';
import { isNumber } from 'helpers/primitives';
import { connectToDatabase } from 'helpers/firestore';
import { getUTMParameters } from 'helpers/url';

// constants
import emails from 'constants/emails';
import { MAILTO_LINK_ID, MAILTO_TEXT_ID } from 'constants/selectors';

// variables utm_campaign
const searchMotionDesign = 'Search_Motion_Design';
const searchInfographie = 'Search_Infographie';
const searchG = 'SearchG';
const animationVideo = 'animation_video';
const PMax = 'PMax';

// variables email
const salut = 'salut@gorille.co';
const hello = 'hello@gorille.co';
const contact = 'contact@gorille.co';
const hi = 'hi@gorille.co';
const demande = 'demande@gorille.co';

const STATIC_EMAIL_INDEX = 'STATIC_EMAIL_INDEX'; // local_storage key

const { domain, subject, emailWord, gorilla, staticEmailWords } = emails[process.env.LANG];

// Remplace les %20 par des espaces
const decodedSubject = decodeURIComponent(subject).replace(/%20/g, ' ');

// eslint-disable-next-line no-unused-vars
let isEmailLinkDynamic = false;

// update email link (mailto) - if UTM
const updateEmailDynamic = async (utmParams, mt) => {

	// check if UTM parameters are found
	if (!isEmpty(utmParams)) {

		// 0. get clientId (set by Google Anlaytics)
		const clientId = await getClientId();
		console.log(`client id is '${clientId}'`);

		// 1. generate unique email (@TODO : get unique email from UTM paramaters + clientId)
		const dynMailId = randomId();

		// 2. update email (updateHTML is run on DOM ready)
		updateHTML(() => {
			const $email = $(`#${MAILTO_TEXT_ID}`);
			const $emailLink = $(`#${MAILTO_LINK_ID}`);

			// Récupérer l'UTM depuis l'URL
			const urlParams = new URLSearchParams(window.location.search);
			const utm_campaign = urlParams.get('utm_campaign');

			if ($email.length !== 0) {
				if (utm_campaign === searchMotionDesign) {
					$email.text(salut);
					$emailLink.attr('href', `mailto:` + `salut+${dynMailId}@${domain}` + `?subject=${encodeURIComponent(decodedSubject + ` à ` + salut)}`);
					isEmailLinkDynamic = true;
				} else if (utm_campaign === searchInfographie) {
					$email.text(hello);
					$emailLink.attr('href', `mailto:` + `hello+${dynMailId}@${domain}` + `?subject=${encodeURIComponent(decodedSubject + ` à ` + hello)}`);
					isEmailLinkDynamic = true;
				} else if (utm_campaign === searchG) {
					$email.text(contact);
					$emailLink.attr('href', `mailto:` + `contact+${dynMailId}@${domain}` + `?subject=${encodeURIComponent(decodedSubject + ` à ` + contact)}`);
					isEmailLinkDynamic = true;
				} else if (utm_campaign === animationVideo) {
					$email.text(hi);
					$emailLink.attr('href', `mailto:` + `hi+${dynMailId}@${domain}` + `?subject=${encodeURIComponent(decodedSubject + ` à ` + hi)}`);
					isEmailLinkDynamic = true;
				} else if (utm_campaign === PMax) {
					$email.text(demande);
					$emailLink.attr('href', `mailto:` + `demande+${dynMailId}@${domain}` + `?subject=${encodeURIComponent(decodedSubject + ` à ` + demande)}`);
					isEmailLinkDynamic = true;
				} else {
					$emailLink.attr('href', mailToLinkString(emailName(emailWord, gorilla), `${emailWord}+${dynMailId}@${domain}`, subject));
					isEmailLinkDynamic = true;
				}
			} else {
				console.error('Impossible de trouver le nœud email');
			}
		});

		// 3. send mapping between unique email and utm_search + cid on server
		try {
			const db = await connectToDatabase();

			if (db) db.collection('mappings').doc(dynMailId).set({ ...utmParams, mt, cid: clientId, gclid: utmParams.gclid })
				.then(() => console.log(`doc written with id '${dynMailId}'`))
				.catch((error) => console.error('error while saving doc to dyn-mail:', error));
		}
		catch (e) {
			console.error(e);
		}
	}
};

// update displayed email (+ link if not modified dynamically) - if valid mt parameter
const updateEmailStatic = (mt) => {

	if (mt && isNumber(mt)) {

		// 0. try to get static mail index from local storage (if page already visited with UTM parameters)
		let staticMailIndex = window.localStorage.getItem(STATIC_EMAIL_INDEX);

		// 1. if none, try to get it from UTM parameters 
		if (staticMailIndex === null) {

			const mtStaticMailIndex = parseInt(mt);

			if (mtStaticMailIndex > 0 & mtStaticMailIndex <= staticEmailWords.length) {
				staticMailIndex = mtStaticMailIndex;
				window.localStorage.setItem(STATIC_EMAIL_INDEX, mtStaticMailIndex); // save it to local storage
			}
		}

		if (staticMailIndex) {

			// 2. get static email address from index
			// eslint-disable-next-line no-unused-vars
			const staticEmailWord = staticEmailWords[staticMailIndex - 1];
			// const staticEmailAddress = `${staticEmailWord}@${domain}`;

			// 3. update email (updateHTML is run on DOM ready)
			updateHTML(() => {
				const $email = $(`#${MAILTO_TEXT_ID}`);
				const $emailLink = $(`#${MAILTO_LINK_ID}`);

				// Récupérer l'UTM depuis l'URL
				const urlParams = new URLSearchParams(window.location.search);
				const utm_campaign = urlParams.get('utm_campaign');

				if ($email.length !== 0) {
					if (utm_campaign === searchMotionDesign) {
						$email.text(salut);
						$emailLink.attr('href', `mailto:` + salut + `?subject=${encodeURIComponent(decodedSubject + ` à ` + salut)}`);
						isEmailLinkDynamic = true;
					} else if (utm_campaign === searchInfographie) {
						$email.text(hello);
						$emailLink.attr('href', `mailto:` + hello + `?subject=${encodeURIComponent(decodedSubject + ` à ` + hello)}`);
						isEmailLinkDynamic = true;
					} else if (utm_campaign === searchG) {
						$email.text(contact);
						$emailLink.attr('href', `mailto:` + contact + `?subject=${encodeURIComponent(decodedSubject + ` à ` + contact)}`);
						isEmailLinkDynamic = true;
					} else if (utm_campaign === animationVideo) {
						$email.text(hi);
						$emailLink.attr('href', `mailto:` + hi + `?subject=${encodeURIComponent(decodedSubject + ` à ` + hi)}`);
						isEmailLinkDynamic = true;
					} else if (utm_campaign === PMax) {
						$email.text(demande);
						$emailLink.attr('href', `mailto:` + demande + `?subject=${encodeURIComponent(decodedSubject + ` à ` + demande)}`);
						isEmailLinkDynamic = true;
					} else {
						// Sinon, conserver le comportement par défaut
						$emailLink.attr('href', mailToLinkString(emailName(emailWord, gorilla), `${emailWord}@${domain}`, subject));
						isEmailLinkDynamic = true;
					}
				} else {
					console.error('Impossible de trouver le nœud email');
				}
			});
		}
		else console.error(`invalid mt parameter ${mt}`);
	}
};

// IIFE
(() => {
	const allParams = getUTMParameters(); // UTM + custom paramaters such as mt
	const { mt, ...utmParams } = allParams;

	// A. update email link (mailto) - if UTM or mt parameters
	updateEmailDynamic(utmParams, mt);

	// B. update email address (displayed) - if valid mt parameter
	updateEmailStatic(mt);
})();
