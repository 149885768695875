// constants / selectors
const PROJECT_ITEM_LIGHTBOX_CLASS = 'project-item-lightbox';
const PROJECT_ITEM_POSTER_CLASS = 'project-item-poster';
const PROJECT_ITEM_PREVIEW_CLASS = 'project-item-preview';
const PROJECT_ITEM_VIDEO_CLASS = 'w-lightbox-embed';

// wait for DOM to be loaded
$(document).ready(() => {

	// jQuery Elements
	const $projectLightbox = $('.' + PROJECT_ITEM_LIGHTBOX_CLASS);

	$projectLightbox.each((projectLightboxIndex, projectLightboxItem) => {

		const $projectLightboxItem = $(projectLightboxItem);
		const $projectPoster = $projectLightboxItem.children('.' + PROJECT_ITEM_POSTER_CLASS);
		const $projectPreview = $projectLightboxItem.children('.' + PROJECT_ITEM_PREVIEW_CLASS);
		const $projectIframe = $projectLightboxItem.find('.' + PROJECT_ITEM_VIDEO_CLASS);


		$projectLightboxItem.mouseenter(() => {
			$projectPoster.css('opacity', '0');
			$projectPreview.attr( 'src', $projectPreview.attr('src') ); // hack: restart gif
			console.log($projectIframe)
		});

		$projectLightboxItem.mouseleave(() => {
			$projectPoster.css('opacity', '1');
		});
	
	});

});
