export const getIpAddress = async () => {

	try {
		// GET request to ipapi.co API
		const response = await fetch('https://ipapi.co/json/');
		const jsonData = await response.json();
		// {
			//   "ip": "116.12.250.1",
			//   "city": "Singapore",
			//   "region": "Central Singapore Community Development Council",
			//   "country": "SG",
			//   "country_name": "Singapore",
			//   "postal": null,
			//   "latitude": 1.2855,
			//   "longitude": 103.8565,
			//   "timezone": "Asia/Singapore"
			// }
		console.log('getIpAddress', JSON.stringify(jsonData, null, 2));
		
		console.log('jsonData.ip', jsonData.ip);
		if (jsonData && 'ip' in jsonData)	return jsonData.ip;
		else return undefined;
	}
	catch (error) {
		console.log('getIpAddress error:', error);
		return undefined;
	}
};
