// async IIFE to allow await. requires top-level await support included in babel/preset-env
(async () => {

	const { default: CookiesEuBanner } = await import(/* webpackChunkName: "cookies-eu-banner" */ 'cookies-eu-banner');

	new CookiesEuBanner(() => {
		// load GTM upon accept
		(function (w, d, s, l, i) {
			w[l] = w[l] || [];
			w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
			var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
			j.async = true;
			j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
			f.parentNode.insertBefore(j, f);
			console.log('GTM loaded');
		})(window, document, 'script', 'dataLayer', process.env.GTM_ID);

	}, true); // <== wait for user to accept cookies
})();