import 'header.js';
import 'pass-url-parameters.js';
import 'cookie-consent.js';
import 'custom-email.js';
import 'text-typing.js';

// fr
// #if process.env.LANG === 'fr'
import 'load-lottie-animations.js';
import 'clients-slider.js';
import 'reviews-slider.js';
import 'process-plane-animation.js';
import 'projects-preview.js';
import 'magic-form-landing.js';
import 'cookie-pardot.js';
import 'success_url_fr.js';
import 'error_url_fr.js';
import 'popup-advertissement-email.js';
// #endif

// es
// #if process.env.LANG === 'es'
// import 'load-lottie-animations.js';
// import 'reviews-slider.js';
// import 'video-buttons.js';
// import 'magic-form.js';
// import 'cookie-pardot.js';
// import 'success_url_en_es.js';
// import 'error_url_en_es.js';
// #endif

// de
// #if process.env.LANG === 'de'
// import 'load-lottie-animations.js';
// import 'clients-slider.js';
// #endif

// uk
// #if process.env.LANG === 'uk'
// import 'load-lottie-animations.js';
// import 'clients-slider.js';
// import 'magic-form.js';
// import 'cookie-pardot.js';
// import 'success_url_en_es.js';
// import 'error_url_en_es.js';
// #endif

// es/de/uk
// #if process.env.LANG !== 'fr'
// import 'projects-slideshow.js';
// #endif

