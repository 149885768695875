export const getClientId = async () => new Promise((resolve, reject) => {
	if (window.clientId) resolve(window.clientId);

	else {
		// clientId passed as the data
		const onClientIdReady = ({ detail: clientId }) => resolve(clientId);

		// clientId_ready event triggered by GTM tag "Get clientId"
		window.addEventListener('clientId_ready', onClientIdReady, false);
	}
});
