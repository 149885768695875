// lottie animations


// fr / es / de / uk
const creation = {
	name: 'creation',
	className: 'lottie--creation',
	path: 'https://uploads-ssl.webflow.com/5db6d64319de553a9bf24669/62ed263bc674b0cff80d6e33_creation-with-pauses.json',
	frameSegments: [
		[[600, 665], [0,131]], // concurrence => transparent, transparent => attention
		[137, 230], // attention => formations
		[239, 369], // formations => produit
		[381, 586], // produit => concurrence
	],
};

// fr
const awards = {
	name: 'awards',
	className: 'lottie--awards',
	path: 'https://assets.website-files.com/5db6d64319de553a9bf24669/5e0d048a3f3d9573aba72152_awards_anim.json'
};

// fr
const play = {
	name: 'play',
	className: 'play-wrapper',
	path: 'https://assets.website-files.com/5db6d64319de553a9bf24669/5fe229ecf8c105fd4f3aee2d_data.json'
};

// fr / es (not same position)
const hug = {
	name: 'hug',
	className: 'lottie--hug',
	path: 'https://assets.website-files.com/5db6d64319de553a9bf24669/5e0d07b90e0ea794f515c5d0_hug_anim.json'
};

// fr / es
const storyboard = {
	name: 'storyboard',
	className: 'lottie--storyboard',
	path: 'https://assets.website-files.com/5db6d64319de553a9bf24669/5e0db1b9b1dab3fd88244e5d_storyboard_anim.json'
};

// fr / es
const vimeo = {
	name: 'vimeo',
	className: 'lottie--vimeo',
	path: 'https://assets.website-files.com/5db6d64319de553a9bf24669/608bf109fd950022f9954c5b_vimeo.json'
};

// fr
const phone = {
	name: 'phone',
	className: 'phone-animation',
	path: 'https://assets.website-files.com/5db6d64319de553a9bf24669/5e0db3f0152007c17a79e8dc_phone_anim.json'
};

// es @TODO
// eslint-disable-next-line no-unused-vars
const pig = {
	name: 'pig',
	className: 'lottie-pig',
	path: 'xyz'
};


const lazyLoadedLottieAnimationsDataPerSite = {
	fr: [creation, awards, play, hug, storyboard, vimeo, phone],
	es: [creation, storyboard, vimeo, hug /*, pig*/],
	de: [creation],
	uk: [creation],
};

export default lazyLoadedLottieAnimationsDataPerSite;

