const emails = {
	fr: {
		domain: 'gorille.co',
		subject: 'Demande%20de%20contact', // @TODO : use URLEncode
		emailWord: 'bonjour',
		gorilla: 'Gorille', // @TODO : use URLEncode
		staticEmailWords: ['salut', 'hello', 'contact', 'hi', 'demande'],
	},

	es: {
		domain: 'gorila-agencia.es',
		subject: 'Solicitud%20de%20contacto', // @TODO : use URLEncode
		emailWord: 'hola',
		gorilla: 'Gorila', // @TODO : use URLEncode
		staticEmailWords: ['hey', 'hello', 'contacto', 'hi', 'solicitud'],
	},

	de: {
		domain: 'gorilla-agentur.de',
		subject: 'Anfrage%20f%C3%BCr%20Kontakt', // @TODO : use URLEncode
		emailWord: 'hallo',
		gorilla: 'Gorilla', // @TODO : use URLEncode
		staticEmailWords: ['hey', 'hello', 'kontakt', 'hi', 'anfrage'],
	},

	uk: {
		domain: 'gorilla-agency.uk',
		subject: 'Contact%20inquiry', // @TODO : use URLEncode
		emailWord: 'hello',
		gorilla: 'Gorilla', // @TODO : use URLEncode
		staticEmailWords: ['hey', 'hello', 'contact', 'hi', 'inquiry'],
	},
};

export default emails;
