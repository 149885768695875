// helpers
import { getUTMParameters } from 'helpers/url';
import { getClientId } from 'helpers/analytics.js';
import 'helpers/jquery.scrolledintoview.js';
import { getIpAddress } from 'helpers/ip-address.js';

// constants
import { CLIENT_ID_FIELD_ID } from 'constants/selectors';

$(document).ready(async () => {

	// 0. get DOM nodes
	const $contactWrapper = $('.contact-wrapper');
	const $clientIdField = $(`#${CLIENT_ID_FIELD_ID}`);

	// 1. set hidden fields value with UTM parameter values (if any)
	// hidden fields need to be present on Webflow (magic-symbol > magic-form-block > magic-form > hidden_fields). So far following hidden fields are present:
	// cid, utm_source, utm_medium, utm_campaign, mt, utm_term, utm_content, utm_support, utm_keyword + ip_address
	// those fields need to be passed in Integromat scenario at Google Sheet step {{1.data.field_name}}
	// paramaters will be dict with field id as key and field value as value
	const paramaters = getUTMParameters();
	paramaters['ip_address'] = await getIpAddress();

	Object.keys(paramaters).forEach((key) => {
		const parameterValue = paramaters[key];
		const $parameterField = $(`#${key}`);

		// if parameter value is not null and corresponding hidden field exists
		if (parameterValue && $parameterField.length !== 0)
			$parameterField.val(parameterValue);
	});

	// 2. load required JS for fields when magic button become visible
	let hasButtonScrolledIntoView = false;

	$contactWrapper.scrolledIntoView({ onEnter: async () => {

		// skip if not first time
		if (hasButtonScrolledIntoView) return;
		else hasButtonScrolledIntoView = true;

		console.log('magic button scrolled into view');

		//  customize phone field
		await import(/* webpackChunkName: "phone-field" */ 'phone-field.js');

		// sync visible slider element with hidden one + text
		await import(/* webpackChunkName: "magic-slider" */ 'magic-slider.js');

		// edit text input file upload
		const uploadcareButton = $('.uploadcare--widget__button.uploadcare--widget__button_type_open');
		if (uploadcareButton) { 
			if (process.env.LANG === 'fr') $(uploadcareButton).text('Choisissez un fichier');
			$(uploadcareButton).on('click', () => {
				// setTimeout(() => {
					if (process.env.LANG === 'fr') {

						// big blue button
						$('.uploadcare--button.uploadcare--button_size_big.uploadcare--button_primary.uploadcare--tab__action-button.needsclick.uploadcare--dragging__hide').text('Choisir un fichier local');
						// or text second choice
						$('.uploadcare--text.uploadcare--text_size_small.uploadcare--text_muted').text('ou');
						$('.uploadcare--text.uploadcare--text_size_extra-large').first().text('Déposez le fichier ici');
						$('.uploadcare--text.uploadcare--text_size_extra-large').last().text('Glissez & déposez un fichier');
					}
				// }, 100);
			});

		}
	}});

	// 4. pass clientId to hidden field
	const clientId = await getClientId();
	if (clientId && $clientIdField.length !== 0) $clientIdField.val(clientId);

	// /!\ Anything after await getClientId() could wait forever
});