// Webflow slider with each slide having a Collection List with source = Reviews and "limit items" = { show : 1, startAt: slideNumber }
// x slides setup in Webflow Designer, if less source items (Reviews) than Slides we remove them in JS

const REVIEWS_SLIDER_CLASS = 'reviews_slider';
const REVIEW_SLIDE_CLASS = 'review_slide';

$(document).ready(() => {

	const $reviewsSlider = $(`.${REVIEWS_SLIDER_CLASS}`);
	
	// 1. remove any slide where containing '.w-dyn-empty' div (where there is no data)
	const $emptyReviews = $reviewsSlider.find('.w-dyn-empty');
	$emptyReviews.parents(`.${REVIEW_SLIDE_CLASS}`).remove();

	// 2. redraw slider so CMS data is displayed (on load CMS data not ready yet but by default slider does not update when it is loaded)
	Webflow.require('slider').redraw();

	// 3. center sliders by triggering tap on second one
	$reviewsSlider.find('.w-round div:nth-child(2)').trigger('click');
});