const pad = (num, size) => {
	var s = num.toString();
	while (s.length < size) s = '0' + s;
	return s;
};

const capitalize = (s) => {
	if (typeof s !== 'string') return '';
	return s.charAt(0).toUpperCase() + s.slice(1);
}

export { pad, capitalize };