// helpers
import { listenEvent, dispatchEvent } from 'helpers/eventMonitor';
import 'helpers/jquery.scrolledintoview.js';

// data
import lottieAnimationsData from 'data/lottie-animations.js';

const forceFlag = true; // false = wait until the current segment is complete. true = update values immediately. see https://github.com/airbnb/lottie-web#playsegmentssegments-forceflag

const DEBUG = false;


$(document).ready(async () => {

	const animations = lottieAnimationsData[process.env.LANG];

	animations.forEach(({ name, className, path, frameSegments }) => {

		const $lottieWrapper = $(`.${className}`);

		let hasAnimScrolledIntoView = false;

		$lottieWrapper.scrolledIntoView({ onEnter: async () => {

			// skip if not first time
			if (hasAnimScrolledIntoView) return;
			else hasAnimScrolledIntoView = true;

			console.log(`lottie anim ${name} scrolled into view`);

			const { default: lottie } = await import(/* webpackChunkName: "lottie-web" */ 'lottie-web');

			const ANIM_NAME = name.toUpperCase();

			const isAnimControlled = frameSegments !== undefined;

			// creation animation, load + wait (played by segments, controlled by typing sequence)
			const instance = lottie.loadAnimation({
				container: $lottieWrapper[0],
				renderer: 'svg',
				loop: !isAnimControlled,
				// initialSegment: frameSegments[0][1],
				autoplay: !isAnimControlled, // do not autoplay creation anim since we use playSegments (autoplay with initialSegment does not play)
				path,
			});

			if (isAnimControlled) {

				let animStep = 0; // holds the current step
				let isFirstRun = true;

				// listen for events from text-typing: play segment when sentence starts
				// Note: (DOM needs to be ready because listenEvent/dispatchEvent are using a div to communicate)
				listenEvent(`START_${ANIM_NAME}_ANIM_SEGMENT`, ({ step }) => {
					if (DEBUG) console.log(`1B. [lottie] will play segment, step ${step}`);

					// on first run (step = 0), play initial segment (without transition from concurrence to formation)
					if (isFirstRun) {
						// Note: we need to wait a bit before calling playSegments otherwise it plays the whole video before playing the segment
						// // @TODO: remove setTimeout, see https://github.com/airbnb/lottie-web/issues/1965
						// setTimeout(() => {
						instance.playSegments(frameSegments[step][1], forceFlag);
						// }, 1000);
						isFirstRun = false;
					}

					else instance.playSegments(frameSegments[step], forceFlag);
					animStep = step; // update step
				});

				// inform text-typing when segment is played: so it starts next sentence starts
				instance.addEventListener('complete', () => {
					if (DEBUG) console.log(`3A. [lottie] segment has completed, step ${animStep}`);
					dispatchEvent(`${ANIM_NAME}_ANIM_SEGMENT_HAS_COMPLETED`, { step: animStep });
				});

				// Inform text-typing when lottie anim ready
				// @TODO: Event might not be received by text-typing if lottie anim loads first. this should be fixed in eventMonitor by using a queue for events and keep them in the queue until until reception confirmation, see issue 41
				instance.addEventListener('DOMLoaded', () => {
					if (DEBUG) console.log('0A. [lottie] lottie anim ready');
					dispatchEvent(`${ANIM_NAME}_ANIM_LOADED`);
				});
			} // end of if

		}}); // end of onEnter

	}); // end of for
});