// from https://gist.github.com/dillansimmons/a2c1ebad5788dc2a2d9cb45669d9396e

// helpers
import { getUTMParameters } from 'helpers/url';
import { updateHTML } from 'helpers/html';
import { toArray } from 'helpers/dict';
import { isEmpty } from 'helpers/dict';


// pass UTM parameters to all links with rel="keep-params"
(() => {
	const urlParams = getUTMParameters(); // UTM + custom paramaters such as mt

	if (!isEmpty(urlParams)) {
		const url_search = toArray(urlParams).join('&');

		updateHTML(() => {
			$('a').attr('href', (index, hrefValue) => `${hrefValue}?${url_search}`);
		});
	}
})();

