window.addEventListener('DOMContentLoaded', () => {
    console.log('pardot loaded');
    // button to reject cookies
    const cookiesEureject = document.getElementById('cookies-eu-reject');
    console.log(cookiesEureject);
    // button to accept cookies
    const cookiesEuaccept = document.getElementById('cookies-eu-accept');
    console.log(cookiesEuaccept);
    if (cookiesEureject != null && cookiesEuaccept != null) {
        // if the user rejects cookies, reject pardot cookies
        cookiesEureject.addEventListener('click', () => {
            console.log('pardot rejected');
            // button to reject pardot cookies
            const cookiesPardotNo = document.getElementById('pi_tracking_opt_in_no');
            if (cookiesPardotNo != null) {
                cookiesPardotNo.click();
            }
        });

        // if the user accepts cookies, accept pardot cookies
        cookiesEuaccept.addEventListener('click', () => {
            console.log('pardot accepted');
            // button to accept pardot cookies
            const cookiesPardotYes = document.getElementById('pi_tracking_opt_in_yes');
            if (cookiesPardotYes != null) {
                cookiesPardotYes.click();
            }
        });
    }
});