const getUTMParameters = () => {
	const fullUrl = window.location.href;
	const queryStringStart = fullUrl.indexOf('?');
	const fragmentStart = fullUrl.indexOf('#');

	let queryString = '';
	if (queryStringStart !== -1) {
		if (fragmentStart !== -1 && fragmentStart > queryStringStart) {
			queryString = fullUrl.substring(queryStringStart, fragmentStart);
		} else {
			queryString = fullUrl.substring(queryStringStart);
		}
	}

	const params = new URLSearchParams(queryString);
	const utm_params = {};
	params.forEach((value, key) => {
		console.log(`param is [${key}]=\`${value}\``);
		if (key.startsWith('utm_')) utm_params[key] = value;
		else if (key === 'mt') utm_params['mt'] = value; // also pass custom mt parameter
		else if (key === 'gclid') utm_params['gclid'] = value; // also pass custom gclid parameter
	});
	return utm_params;
};

export { getUTMParameters };
