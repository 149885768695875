// for gorille FR
document.addEventListener("DOMContentLoaded", function () {
    if (window.location.href.includes('success=true')) {
        const form = document.getElementById("wf-form-magic-form");
        const success = document.getElementById("wf-form-magic-success");
        form.style.display = "none";
        success.style.display = "block";
    } else {
        const form = document.getElementById("wf-form-magic-form");
        const success = document.getElementById("wf-form-magic-success");
        form.style.display = "block";
        success.style.display = "none";
    }
});