document.addEventListener("DOMContentLoaded", function () {
    const inputEmail = document.getElementById('field');
    const popupAdvertissementEmail = document.getElementById('popupAdvertissementEmail');
    const closePopup = document.getElementById('close-popup');

    inputEmail.addEventListener('blur', function () {
        const email = this.value.trim();
        if (email.endsWith('@gmail.com') || email.endsWith('@hotmail.com') || email.endsWith('@yahoo.com') || email.endsWith('@outlook.com')) {
            popupAdvertissementEmail.style.display = 'flex';
            popupAdvertissementEmail.style.opacity = '1';
        }
    });

    closePopup.addEventListener('click', function () {
        popupAdvertissementEmail.style.display = 'none';
        popupAdvertissementEmail.style.opacity = '0';
    });
});