var Webflow = Webflow || [];
Webflow.push(function () {
	// Dark bg nav tweak
		$('.lottie-burger').on("click", function(){
				if ($('.dark_nav_compensate').is(":visible") && ($(document).scrollTop() < 120 )) { 
				//console.log('not scrolled past');    
						if ($('.navlinks_wrapper').is(":hidden")) {
								//console.log('open-menu');
								$('.gorille-logo').css('color','#0c1153');
								$('.lottie-burger').css('filter','invert(0)');
						}
						else {
								//console.log('close-menu');
								$('.gorille-logo').css('color','white');
								$('.lottie-burger').css('filter','invert(100%)');
						}
				}
		});
});