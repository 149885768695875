// IDs
export const MAILTO_TEXT_ID = 'mailto-text';
export const MAILTO_LINK_ID = 'mailto-link'; // ex contact-mailto
export const PHONE_INPUT_ID = 'phone-input';
// export const CALENDLY_LINK_ID = 'calendly-link';
export const PHONE_LINK_ID = 'phone-link'; // ex contact_phone-link
export const CONTACT_BUTTON_ID = 'contact-button';
export const PRICING_SLIDER_ID = 'pricing-slider';
export const MAGIC_FORM_DURATION_SLIDER_ID = 'quote-request__duration-slider';
export const PRICING_SLIDER_BACKGROUND_MASK_ID = 'interactive-pricing__background-mask';
export const TEXT_TYPING_ID = 'text-typing';
export const THANK_YOU_ANSWER_TEXT_ID = 'answer-text';
export const CLIENT_ID_FIELD_ID = 'cid';

// anchors
export const CONTACT_SECTION_ANCHOR = 'contact';